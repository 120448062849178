var render, staticRenderFns
import script from "./ProcessesDoughnut.ts?vue&type=script&lang=ts&"
export * from "./ProcessesDoughnut.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2774308336/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62ba6495')) {
      api.createRecord('62ba6495', component.options)
    } else {
      api.reload('62ba6495', component.options)
    }
    
  }
}
component.options.__file = "src/Modules/Dashboard/components/ProcessesSection/ProcessesDoughnut/ProcessesDoughnut.vue"
export default component.exports