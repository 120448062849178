import { render, staticRenderFns } from "./Atcom.vue?vue&type=template&id=316a8814&scoped=true&"
import script from "./Atcom.ts?vue&type=script&lang=ts&"
export * from "./Atcom.ts?vue&type=script&lang=ts&"
import style0 from "./Atcom.vue?vue&type=style&index=0&id=316a8814&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "316a8814",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2774308336/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('316a8814')) {
      api.createRecord('316a8814', component.options)
    } else {
      api.reload('316a8814', component.options)
    }
    module.hot.accept("./Atcom.vue?vue&type=template&id=316a8814&scoped=true&", function () {
      api.rerender('316a8814', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/History/pages/Atcom/Atcom.vue"
export default component.exports