import { render, staticRenderFns } from "./ChangePassword.vue?vue&type=template&id=35271884&scoped=true&"
import script from "./ChangePassword.ts?vue&type=script&lang=ts&"
export * from "./ChangePassword.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35271884",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2774308336/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35271884')) {
      api.createRecord('35271884', component.options)
    } else {
      api.reload('35271884', component.options)
    }
    module.hot.accept("./ChangePassword.vue?vue&type=template&id=35271884&scoped=true&", function () {
      api.rerender('35271884', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Auth/pages/ChangePassword/ChangePassword.vue"
export default component.exports