import { render, staticRenderFns } from "./DownloadButton.vue?vue&type=template&id=c1e162f0&scoped=true&"
import script from "./DownloadButton.ts?vue&type=script&lang=ts&"
export * from "./DownloadButton.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1e162f0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2774308336/src/sctd-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c1e162f0')) {
      api.createRecord('c1e162f0', component.options)
    } else {
      api.reload('c1e162f0', component.options)
    }
    module.hot.accept("./DownloadButton.vue?vue&type=template&id=c1e162f0&scoped=true&", function () {
      api.rerender('c1e162f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Modules/Processes/components/details/DocumentsTab/TableFiles/DownloadButton/DownloadButton.vue"
export default component.exports